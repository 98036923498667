<template>
  <div>
    <BaseSearchPage class="mt-0">
      <template v-slot:form>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-tabs
            v-model="tab"
            right
            v-if="tabs.length"
            class="b-tab-horizontal"
          >
            <template v-for="(header, index) in tabs">
              <v-tab :href="`#tab-${index}`" :key="index">
                <v-icon left :size="20">
                  {{ getProductTypeIcon(header.productType) }}
                </v-icon>
                {{ getProductTypeName(header.productType) }}
              </v-tab>
            </template>

            <v-tabs-items v-model="tab">
              <template v-for="(model, index) in tabs">
                <v-tab-item :value="`tab-${index}`" :key="index">
                  <v-row dense class="mt-5">
                    <v-data-table
                      class="b-table-exclusion"
                      dense
                      disable-sort
                      hide-default-footer
                      item-key="id"
                      :headers="headers"
                      :items="model.dependents"
                    >
                      <template v-slot:[`item.kinship`]="{ item }">
                        <span
                          :class="[
                            item.kinship === 'Titular' && 'primary--text'
                          ]"
                        >
                          {{ item.kinship }}
                        </span>
                      </template>
                      <template v-slot:[`item.value`]="{ item }">
                        {{ formatMoney(item.value) }}
                      </template>
                    </v-data-table>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                      <v-row dense>
                        <v-col cols="12">
                          <h5 class="mt-5 mb-3">Plano atual</h5>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Número ou nome do contrato"
                            dense
                            outlined
                            disabled
                            v-model="model.currentContract"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Plano e valor atual"
                            dense
                            outlined
                            disabled
                            v-model="model.currentPlan"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                      <v-row dense>
                        <v-col cols="12">
                          <h5 class="mt-5 mb-3">Novo plano</h5>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            label="Número ou nome do contrato"
                            item-value="id"
                            item-text="description"
                            dense
                            outlined
                            :items="model.availableContracts"
                            required
                            :rules="[required]"
                            v-model="model.newContractId"
                            @change="onChange(model)"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            label="Novo plano e valor"
                            item-value="id"
                            item-text="description"
                            dense
                            outlined
                            :items="
                              model.productType === 'Assistência médica'
                                ? healthPlans
                                : dentalPlans
                            "
                            :rules="model.newContractId ? [required] : []"
                            v-model="model.newPlanId"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h5 class="mb-3">Dia de vencimento e corte</h5>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <v-text-field
                        label="Dia de vencimento"
                        outlined
                        disabled
                        dense
                        v-model="model.expiryDay"
                      />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <v-text-field
                        label="Dia de corte"
                        outlined
                        disabled
                        dense
                        v-model="model.cutOffDay"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h5 class="mb-3">Datas de controle do plano</h5>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <BaseDatePicker
                        label="Data de início"
                        :rules="model.newContractId ? [required] : []"
                        v-model="model.startDate"
                      />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <BaseDatePicker
                        label="Data de envio"
                        :rules="model.newContractId ? [required] : []"
                        v-model="model.sendDate"
                      />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <BaseDatePicker
                        label="Data de conclusão"
                        :rules="model.newContractId ? [required] : []"
                        v-model="model.conclusionDate"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <h5 class="mb-3">
                        Valor de cobrança de acordo com os dias de uso
                      </h5>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <v-text-field
                        label="Mês de cobrança"
                        outlined
                        disabled
                        dense
                        v-model="model.billingMonth"
                      />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                      <v-text-field
                        label="Quantidade de dias"
                        outlined
                        disabled
                        dense
                        v-model="model.amountDays"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-tabs>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row dense>
      <v-col cols="6">
        <MovementsDocuments :employeeId="employeeId" />
      </v-col>
      <v-col cols="6" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
        <BaseButton
          id="btn-save"
          name="btn-save"
          color="primary"
          :title="btnSave"
          :disabled="!valid"
          @click="save"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { formatCpf, formatMoney } from '@/helpers/formatting';
import { getProductTypeName, getProductTypeIcon } from '@/helpers/utils';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    MovementsDocuments: () => import('../details/movements-documents')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    tab: null,
    btnSave: 'Salvar',
    headers: [
      { text: 'Beneficiário', value: 'name' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Valor', value: 'value' }
    ],
    tabs: [],
    healthPlans: [],
    dentalPlans: []
  }),

  mounted() {
    this.search();
  },

  methods: {
    formatCpf,
    formatMoney,
    getProductTypeName,
    getProductTypeIcon,

    back() {
      this.$emit('back');
    },

    async save() {
      if (!this.$refs.form.validate(true)) return;

      if (this.tab === 'tab-0' && this.tabs.length > 1) {
        this.tab = 'tab-1';
        this.btnSave = 'Salvar';
        return;
      }

      try {
        const movementService = new MovementService();
        const { status } = await movementService.putMovementPlanChange({
          model: this.tabs,
          employeeId: this.employeeId
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getPlanChangeMovement({
          employeeId: this.employeeId,
          contracts:
            this.contracts && this.contracts.length
              ? this.contracts.join()
              : null
        });

        if (status === 200) {
          this.tabs = data;
          this.setBtnText(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    setBtnText(result) {
      if (result.length > 1) {
        this.btnSave = 'Avançar';
      } else {
        this.btnSave = 'Salvar';
      }
    },

    async onChange(model) {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getMovementPlanChangePlan({
            contractId: model.newContractId,
            productType: model.productType,
            employeeId: this.employeeId
          });

        if (status === 200) {
          if (model.productType === 'Assistência médica') {
            this.healthPlans = data;
          } else {
            this.dentalPlans = data;
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    contracts() {
      this.search();
    },

    tab(newValue) {
      if (newValue === 'tab-0' && this.tabs.length > 1) {
        this.btnSave = 'Avançar';
      } else {
        this.btnSave = 'Salvar';
      }
    }
  }
};
</script>
