<template>
  <div>
    <BaseSearchPage
      class="mt-0"
      title="O titular que está em remissão possui os dependentes listados abaixo, os mesmo serão mantidos no plano"
    >
      <template v-slot:form>
        <v-row dense>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12">
            <v-data-table
              dense
              item-key="id"
              disable-sort
              hide-default-footer
              :headers="headers"
              :items="items"
            >
              <template v-slot:[`item.document`]="{ item }">
                {{ formatCpf(item.document) }}
              </template>

              <template v-slot:[`item.healthValue`]="{ item }">
                {{ formatMoney(item.healthValue) }}
              </template>

              <template v-slot:[`item.dentalValue`]="{ item }">
                {{ formatMoney(item.dentalValue) }}
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12">
            <h5 class="my-5">Preencha as informações abaixo</h5>
          </v-col>
        </v-row>

        <v-form ref="form" lazy-validation v-model="valid">
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-row dense>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <BaseDatePicker
                    id="remissionDate"
                    name="remissionDate"
                    label="Data de remissão"
                    hide-details
                    required
                    :rules="[required]"
                    v-model="model.remissionDate"
                  />
                </v-col>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <BaseDatePicker
                    id="remissionSendDate"
                    name="remissionSendDate"
                    label="Data de envio"
                    hide-details
                    required
                    :rules="[required]"
                    v-model="model.remissionSendDate"
                  />
                </v-col>
                <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <BaseDatePicker
                    id="remissionEndDate"
                    name="remissionEndDate"
                    label="Data de fim"
                    hide-details
                    required
                    :rules="[required]"
                    v-model="model.remissionEndDate"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xl="8" lg="8" md="8" sm="6">
              <v-textarea
                id="remissionReason"
                name="remissionReason"
                label="Motivo do cancelamento..."
                dense
                outlined
                hide-details
                :height="136"
                required
                :rules="[required]"
                v-model="model.remissionReason"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row dense>
      <v-col cols="6">
        <MovementsDocuments :employeeId="employeeId" />
      </v-col>
      <v-col cols="6" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
        <BaseButton
          id="btn-save"
          name="btn-save"
          color="primary"
          title="Salvar"
          :disabled="!valid"
          @click="save"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { formatCpf, formatMoney } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    MovementsDocuments: () => import('../details/movements-documents')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    headers: [
      { text: 'Dependentes', value: 'name' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'CPF', value: 'document' },
      { text: 'Valor saúde', value: 'healthValue' },
      { text: 'Valor odonto', value: 'dentalValue' }
    ],
    model: {
      remissionDate: null,
      remissionSendDate: null,
      remissionEndDate: null,
      remissionReason: null
    },
    items: []
  }),

  mounted() {
    this.search();
  },

  methods: {
    formatCpf,
    formatMoney,

    back() {
      this.$emit('back');
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const movementService = new MovementService();
        const { status } = await movementService.saveMovementRemission({
          ...this.model,
          ...{ employeeId: this.employeeId, contracts: this.contracts }
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getMovementRemission({
          employeeId: this.employeeId,
          contractId:
            this.contracts && this.contracts.length
              ? this.contracts.join()
              : null
        });

        if (status === 200) {
          this.items = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    contracts() {
      this.search();
    }
  }
};
</script>
