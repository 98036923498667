<template>
  <BaseSearchPage
    class="mt-0"
    title="Selecione os contratos que deseja realizar as alterações"
  >
    <template v-slot:form>
      <v-form @submit.prevent="init" ref="form" lazy-validation v-model="valid">
        <v-row dense>
          <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="6">
            <v-text-field
              id="customer"
              name="customer"
              label="Cliente"
              hide-details
              dense
              outlined
              readonly
              v-model="customer"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="primary">
                      fa-solid fa-circle-info
                    </v-icon>
                  </template>
                  <span>
                    Para mudar o cliente, volte para o menu movimentações
                  </span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <BaseContractsMovements
              id="contracts"
              name="contracts"
              label="Contratos"
              hide-details
              required
              :customerId="customerId"
              :productType="productType"
              :rules="[requiredLength]"
              v-model="info.contracts"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="6">
            <BaseButton
              id="btn-init"
              name="btn-init"
              type="submit"
              height="40"
              color="primary"
              title="Iniciar"
              :disabled="!valid"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseSearchPage>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';

export default {
  mixins: [rulesMixin],

  props: {
    customerId: {
      type: String,
      required: true
    },
    employeeId: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: false
    }
  },

  data: () => ({
    valid: true,
    info: {
      contracts: []
    }
  }),

  computed: {
    customer() {
      return this.$store.getters['movements/customerName'];
    }
  },

  methods: {
    init() {
      if (!this.$refs.form.validate(true)) {
        return;
      } else {
        this.$emit('selectedContract', this.info.contracts);
      }
    }
  }
};
</script>
