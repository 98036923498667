<template>
  <v-container>
    <BaseTitlePage class="mb-0" :title="checkTitle" />

    <MovementsDetail
      :employeeId="$route.params.employeeId"
      :employeeOrDependentId="$route.params.employeeOrDependentId"
    />

    <MovementsHeader
      :employeeId="$route.params.employeeOrDependentId"
      :customerId="$route.params.customerId"
      :productType="productType"
      @selectedContract="selectedContract($event)"
    />

    <component
      v-if="visible"
      :is="$route.params.component"
      :employeeId="$route.params.employeeOrDependentId"
      :contracts="contracts"
      @back="back()"
    />
  </v-container>
</template>

<script>
import MovementsDetail from '@/components/pages/movements/details/movements-detail';
import MovementsHeader from '@/components/pages/movements/details/movements-header';
import PageAgeGroup from '@/components/pages/movements/cards/card-age-group';
import PagePlans from '@/components/pages/movements/cards/card-plans';
import PageRemission from '@/components/pages/movements/cards/card-remission';
import PageRetirement from '@/components/pages/movements/cards/card-retirement';
import PageResignation from '@/components/pages/movements/cards/card-resignation';
import PageTransfer from '@/components/pages/movements/cards/card-transfer';

export default {
  components: {
    MovementsDetail,
    MovementsHeader,
    PageAgeGroup,
    PagePlans,
    PageRemission,
    PageRetirement,
    PageResignation,
    PageTransfer
  },

  data: () => ({
    visible: false,
    contracts: []
  }),

  computed: {
    checkTitle() {
      const { title } = this.$route.params;
      return `Movimentações - ${title}`;
    },

    productType() {
      let type = null;
      const { component } = this.$route.params;

      switch (component) {
        case 'PageAgeGroup':
          type = 'Assistência médica';
          break;
        case 'PageRemission':
        case 'PageResignation':
        case 'PageRetirement':
        case 'PagePlans':
          type = 'Assistência médica,Assistência odontológica';
          break;
        default:
          type = null;
          break;
      }

      return type;
    }
  },

  methods: {
    back() {
      const { customerId, employeeId, employeeOrDependentId } =
        this.$route.params;

      this.$router.push({
        name: 'movimentacoes-edit',
        params: { customerId, employeeId, employeeOrDependentId }
      });
    },

    selectedContract(event) {
      this.contracts = event;
      this.visible = true;
    }
  }
};
</script>
