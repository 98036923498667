<template>
  <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
    <BaseSearchPage
      class="mt-0"
      title="Confira agora os dados do plano contratado e qual o estipulante / subestipulante o funcionário está alocado"
    >
      <template v-slot:form>
        <v-row dense>
          <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
            <v-text-field
              id="currentCustomer"
              name="currentCustomer"
              label="Subestipulante atual"
              dense
              outlined
              disabled
              v-model="model.currentCustomer"
            />
          </v-col>
          <v-col
            cols="12"
            :xl="1"
            :lg="1"
            :md="1"
            :sm="2"
            class="text-center pt-3"
          >
            <v-icon color="primary">fa-solid fa-right-left</v-icon>
          </v-col>
          <v-col cols="12" :xl="7" :lg="7" :md="7" :sm="5">
            <v-autocomplete
              id="customerId"
              name="customerId"
              label="Novo subestipulante"
              dense
              outlined
              item-value="id"
              item-text="name"
              required
              :rules="[required]"
              :items="model.customers"
              @change="selectedCustomer"
              v-model="model.customerId"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="model.currentDentalPlan">
          <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
            <v-text-field
              id="currentDentalPlan"
              name="currentDentalPlan"
              label="Plano atual odonto"
              dense
              outlined
              disabled
              v-model="model.currentDentalPlan"
            />
          </v-col>
          <v-col
            cols="12"
            :xl="1"
            :lg="1"
            :md="1"
            :sm="2"
            class="text-center pt-3"
          >
            <v-icon color="primary">fa-solid fa-right-left</v-icon>
          </v-col>
          <v-col cols="12" :xl="3" :lg="3" :md="3" :sm="5">
            <v-autocomplete
              id="dentalContractId"
              name="dentalContractId"
              label="Novo contrato odonto"
              dense
              outlined
              item-value="id"
              item-text="description"
              required
              :rules="[required]"
              :items="availableTransferContractsDental"
              @change="selectDentalContract"
              v-model="model.dentalContractId"
            />
          </v-col>
          <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
            <v-autocomplete
              id="dentalPlanId"
              name="dentalPlanId"
              label="Novo plano odonto"
              dense
              outlined
              item-value="id"
              item-text="description"
              required
              :rules="[required]"
              :items="availablePlanTransferDental"
              v-model="model.dentalPlanId"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="model.currentHealthPlan">
          <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
            <v-text-field
              id="currentHealthPlan"
              name="currentHealthPlan"
              label="Plano atual saúde"
              dense
              outlined
              disabled
              v-model="model.currentHealthPlan"
            />
          </v-col>
          <v-col
            cols="12"
            :xl="1"
            :lg="1"
            :md="1"
            :sm="2"
            class="text-center pt-3"
          >
            <v-icon color="primary">fa-solid fa-right-left</v-icon>
          </v-col>
          <v-col cols="12" :xl="3" :lg="3" :md="3" :sm="5">
            <v-autocomplete
              id="healthContractId"
              name="healthContractId"
              label="Novo contrato saúde"
              dense
              outlined
              item-value="id"
              item-text="description"
              clearable
              required
              :rules="[required]"
              :items="availableTransferContractsHealth"
              @change="selectHealthContract"
              v-model="model.healthContractId"
            />
          </v-col>
          <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
            <v-autocomplete
              id="healthPlanId"
              name="healthPlanId"
              label="Novo plano saúde"
              dense
              outlined
              item-value="id"
              item-text="description"
              required
              :rules="[required]"
              :items="availablePlanTransferHealth"
              v-model="model.healthPlanId"
            />
          </v-col>
        </v-row>

        <v-divider class="mb-5" />

        <v-row dense>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12">
            <h5 class="mb-3">Matrícula</h5>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <v-text-field
              id="currentRegistration"
              name="currentRegistration"
              label="Matrícula atual"
              dense
              outlined
              disabled
              v-model="model.currentRegistration"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <v-text-field
              id="registration"
              name="registration"
              label="Nova matrícula"
              dense
              outlined
              required
              :rules="[required]"
              v-model="model.registration"
            />
          </v-col>
        </v-row>

        <v-divider class="mb-5" />

        <v-row dense>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12">
            <h5 class="mb-3">Informe os períodos</h5>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <BaseDatePicker
              id="effectiveDate"
              name="effectiveDate"
              label="Início de vigência"
              required
              :rules="[required]"
              v-model="model.effectiveDate"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="6">
            <BaseDatePicker
              id="effectiveEndDate"
              name="effectiveEndDate"
              label="Fim da vigência"
              required
              :rules="[required]"
              v-model="model.effectiveEndDate"
            />
          </v-col>
          <v-col cols="12" xl="12" lg="12" md="12" sm="12">
            <h5 class="mb-3">O titular possui os seguintes dependentes:</h5>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="b-table-report"
              disable-sort
              hide-default-footer
              item-key="id"
              :headers="headers"
              :items="model.dependents"
            >
              <template v-slot:[`item.document`]="{ item }">
                {{ formatCpf(item.document) }}
              </template>

              <template v-slot:[`item.dentalPlanValue`]="{ item }">
                {{ formatMoney(item.dentalPlanValue) }}
              </template>

              <template v-slot:[`item.healthPlanValue`]="{ item }">
                {{ formatMoney(item.healthPlanValue) }}
              </template>

              <template v-slot:[`body.append`]>
                <tr class="b-table-report__count">
                  <td colspan="3"></td>
                  <td>
                    <strong class="success--text">
                      {{ formatMoney(model.totalHealthValue) }}
                    </strong>
                  </td>
                  <td>
                    <strong class="success--text">
                      {{ formatMoney(model.totalDentalValue) }}
                    </strong>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </template>
    </BaseSearchPage>

    <v-row dense>
      <v-col cols="6">
        <MovementsDocuments :employeeId="employeeId" />
      </v-col>
      <v-col cols="6" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
        <BaseButton
          id="btn-save"
          name="btn-save"
          type="submit"
          color="primary"
          title="Confirmar"
          :disabled="!valid"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { formatMoney, formatCpf } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    MovementsDocuments: () => import('../details/movements-documents')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'CPF', value: 'document' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Valor saúde', value: 'healthPlanValue' },
      { text: 'Valor odonto', value: 'dentalPlanValue' }
    ],
    model: {
      customerId: null,
      currentCustomer: null,
      currentHealthPlan: null,
      currentDentalPlan: null,
      dependents: [],
      customers: [],
      totalDentalValue: 0,
      totalHealthValue: 0,
      healthContractId: null,
      dentalContractId: null,
      healthPlanId: null,
      dentalPlanId: null,
      effectiveDate: null,
      effectiveEndDate: null,
      currentRegistration: null,
      registration: null
    },
    availableTransferContractsHealth: [],
    availableTransferContractsDental: [],
    availablePlanTransferHealth: [],
    availablePlanTransferDental: []
  }),

  mounted() {
    this.search();
  },

  methods: {
    formatMoney,
    formatCpf,

    back() {
      this.$emit('back');
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      let params = Object.assign({}, this.model);
      params.employeeId = this.employeeId;
      params.contracts = this.contracts;
      delete params.currentCustomer;
      delete params.currentDentalPlan;
      delete params.currentHealthPlan;
      delete params.customers;
      delete params.dependents;
      delete params.totalDentalValue;
      delete params.totalHealthValue;

      try {
        const movementService = new MovementService();
        const { status } = await movementService.putTransferBetweenCustomers(
          params
        );

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getTransferBetweenCustomers({
            employeeId: this.employeeId,
            contractId:
              this.contracts && this.contracts.length
                ? this.contracts.join()
                : null
          });

        if (status === 200) {
          data.customerId = null;
          this.model = data;
          this.model.registration = null;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async getContracts(params) {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getAvailableTransferContracts(params);

        if (status === 200) {
          if (params.productType === 'Assistência médica') {
            this.availableTransferContractsHealth = data;
          } else {
            this.availableTransferContractsDental = data;
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    selectedCustomer() {
      if (this.model.currentHealthPlan) {
        this.model.healthContractId = null;
        this.model.healthPlanId = null;
        this.availablePlanTransferHealth = [];

        this.getContracts({
          customerId: this.model.customerId,
          productType: 'Assistência médica'
        });
      }

      if (this.model.currentDentalPlan) {
        this.model.dentalContractId = null;
        this.model.dentalPlanId = null;
        this.availablePlanTransferDental = [];

        this.getContracts({
          customerId: this.model.customerId,
          productType: 'Assistência odontológica'
        });
      }

      this.$refs.form.resetValidation();
    },

    async getPlans(params) {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getAvailablePlanTransfer(
          params
        );

        if (status === 200) {
          if (params.productType === 'Assistência médica') {
            this.availablePlanTransferHealth = data;
          } else {
            this.availablePlanTransferDental = data;
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    selectDentalContract() {
      if (this.model.dentalContractId) {
        this.model.dentalPlanId = null;

        this.getPlans({
          contractId: this.model.dentalContractId,
          employeeId: this.employeeId,
          productType: 'Assistência odontológica'
        });

        this.$refs.form.resetValidation();
      }
    },

    selectHealthContract() {
      if (this.model.healthContractId) {
        this.model.healthPlanId = null;

        this.getPlans({
          contractId: this.model.healthContractId,
          employeeId: this.employeeId,
          productType: 'Assistência médica'
        });

        this.$refs.form.resetValidation();
      }
    }
  },

  watch: {
    contracts() {
      this.search();
    }
  }
};
</script>
