<template>
  <div>
    <BaseSearchPage class="mt-0" title="Informe uma nova data de nascimento">
      <template v-slot:icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="primary" class="ml-2">
              fa-solid fa-circle-info
            </v-icon>
          </template>
          <span>
            No caso de planos que estejam na categoria de faixa etária o valor
            sofrerá alterações.
          </span>
        </v-tooltip>
      </template>
      <template v-slot:form>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-row dense>
            <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
              <BaseDatePicker
                id="currentBirthDate"
                name="currentBirthDate"
                label="Data de nascimento atual"
                disabled
                v-model="model.currentBirthDate"
              />
            </v-col>
            <v-col
              cols="12"
              :xl="1"
              :lg="1"
              :md="1"
              :sm="2"
              class="text-center pt-3"
            >
              <v-icon color="primary">fa-solid fa-right-left</v-icon>
            </v-col>
            <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
              <BaseDatePicker
                id="newBirthDate"
                name="newBirthDate"
                label="Nova data de nascimento"
                required
                :rules="[required]"
                @change="search(true)"
                v-model="model.newBirthDate"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
              <v-text-field
                id="currentPlan"
                name="currentPlan"
                label="Plano e valor atual"
                dense
                outlined
                disabled
                v-model="model.currentPlan"
              />
            </v-col>
            <v-col
              cols="12"
              :xl="1"
              :lg="1"
              :md="1"
              :sm="2"
              class="text-center pt-3"
            >
              <v-icon color="primary">fa-solid fa-right-left</v-icon>
            </v-col>
            <v-col cols="12" :xl="4" :lg="4" :md="4" :sm="5">
              <v-text-field
                id="newPlan"
                name="newPlan"
                label="Novo plano e valor"
                dense
                outlined
                disabled
                v-model="model.newPlan"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row dense>
      <v-col cols="6">
        <MovementsDocuments :employeeId="employeeId" />
      </v-col>
      <v-col cols="6" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
        <BaseButton
          id="btn-save"
          name="btn-save"
          color="primary"
          title="Salvar"
          :disabled="!valid"
          @click="save"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    MovementsDocuments: () => import('../details/movements-documents')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      currentBirthDate: null,
      newBirthDate: null,
      currentPlan: null,
      newPlan: null
    }
  }),

  mounted() {
    this.search(false);
  },

  methods: {
    back() {
      this.$emit('back');
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const movementService = new MovementService();
        const { status } = await movementService.changeAgeGroup({
          employeeId: this.employeeId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search(newDate) {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getChangeAgeGroup({
          contractId:
            this.contracts && this.contracts.length ? this.contracts[0] : null,
          employeeId: this.employeeId,
          newBirthDate: newDate ? this.model.newBirthDate.split('T')[0] : null
        });

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    contracts() {
      this.search(false);
    }
  }
};
</script>
