<template>
  <div>
    <BaseSearchPage class="mt-0">
      <template v-slot:form>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-tabs
            v-model="tab"
            right
            v-if="tabs.length"
            class="b-tab-horizontal"
          >
            <template v-for="(header, index) in tabs">
              <v-tab :href="`#tab-${index}`" :key="index">
                <v-icon left :size="20">
                  {{ getProductTypeIcon(header.productType) }}
                </v-icon>
                {{ getProductTypeName(header.productType) }}
              </v-tab>
            </template>

            <v-tabs-items v-model="tab">
              <template v-for="(model, index) in tabs">
                <v-tab-item :value="`tab-${index}`" :key="index">
                  <v-row dense class="mt-5">
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <v-radio-group
                        label="O funcionário vai permanecer no plano?"
                        class="ma-0"
                        row
                        hide-details
                        v-model="model.stayPlan"
                      >
                        <v-radio
                          label="Sim"
                          color="primary"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="Não"
                          color="primary"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                      <v-row dense>
                        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <BaseDatePicker
                            label="Data de demissão"
                            hide-details
                            required
                            :rules="[required]"
                            v-model="model.resignationDate"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          xl="12"
                          lg="12"
                          md="12"
                          sm="12"
                          v-if="model.stayPlan"
                        >
                          <BaseDatePicker
                            label="Início de contribuição"
                            hide-details
                            required
                            :rules="[required]"
                            v-model="model.startContributionDate"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          xl="12"
                          lg="12"
                          md="12"
                          sm="12"
                          v-if="model.stayPlan"
                        >
                          <BaseDatePicker
                            label="Fim de contribuição"
                            hide-details
                            required
                            :rules="[required]"
                            v-model="model.endContributionDate"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="8" lg="8" md="8" sm="6">
                      <v-textarea
                        label="Motivo do demissão..."
                        dense
                        outlined
                        hide-details
                        required
                        :rules="[required]"
                        :height="136"
                        v-model="model.reason"
                      />
                    </v-col>
                  </v-row>

                  <v-divider class="mt-5" />

                  <v-row dense>
                    <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <p class="my-4">
                        Informe abaixo o cenário de funcionário contributário
                      </p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <BaseDatePicker
                        label="Data de envio"
                        hide-details
                        required
                        :rules="[required]"
                        v-model="model.sendDate"
                      />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <BaseDatePicker
                        label="Data de fim"
                        hide-details
                        required
                        :rules="[required]"
                        v-model="model.endDate"
                      />
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                      <v-text-field
                        label="Plano e valor"
                        dense
                        outlined
                        disabled
                        v-model="model.planAndValue"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </v-tabs>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row dense>
      <v-col cols="6">
        <MovementsDocuments :employeeId="employeeId" />
      </v-col>
      <v-col cols="6" class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
        <BaseButton
          id="btn-save"
          name="btn-save"
          color="primary"
          :title="btnSave"
          :disabled="!valid"
          @click="save"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { getProductTypeName, getProductTypeIcon } from '@/helpers/utils';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  components: {
    MovementsDocuments: () => import('../details/movements-documents')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    tab: null,
    btnSave: 'Salvar',
    tabs: []
  }),

  mounted() {
    this.search();
  },

  methods: {
    getProductTypeName,
    getProductTypeIcon,

    setBtnText(result) {
      if (result.length > 1) {
        this.btnSave = 'Avançar';
      } else {
        this.btnSave = 'Salvar';
      }
    },

    back() {
      this.$emit('back');
    },

    async save() {
      if (!this.$refs.form.validate(true)) return;

      if (this.tab === 'tab-0' && this.tabs.length > 1) {
        this.tab = 'tab-1';
        this.btnSave = 'Salvar';
        return;
      }

      let params = this.tabs;
      params.forEach(element => {
        element.contracts = this.contracts.join();
      });

      try {
        const movementService = new MovementService();
        const { status } = await movementService.putMovementResignation({
          model: params,
          employeeId: this.employeeId
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getMovementResignation({
          employeeId: this.employeeId,
          contracts:
            this.contracts && this.contracts.length
              ? this.contracts.join()
              : null
        });

        if (status === 200) {
          this.tabs = data;
          this.setBtnText(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    contracts() {
      this.search();
    },

    tab(newValue) {
      if (newValue === 'tab-0' && this.tabs.length > 1) {
        this.btnSave = 'Avançar';
      } else {
        this.btnSave = 'Salvar';
      }
    }
  }
};
</script>
